import React, { useState, useEffect } from 'react';
import {
    CModal,
    CModalHeader,
    CModalTitle,
    CModalBody,
    CModalFooter,
    CButton,
    CForm,
    CCol,
    CFormInput,
    CFormLabel,
} from '@coreui/react'
import { getToken } from '../../App'
import axios from 'axios'

const config = {
    headers: {
        'Authorization': 'Bearer ' + getToken()
    }
};

const AddUserModal = ({ visible, setVisible }) => {

    const [userName, setUserName] = useState('')
    const [passWord, setPassWord] = useState('')
    const [fullName, setFullName] = useState('')
    const [role, setRole] = useState('')

    const [value, newValue] = useState({})
    const handleSubmit = (e) => {
        e.preventDefault()
        newValue({ username: userName, password: passWord, fullname: fullName, role: role })
        setVisible(false)
    }

    useEffect(() => {
        axios.post('https://l-sq.co/user/register', {
            username: userName,
            password: passWord,
            fullname: fullName,
            role: role,
        }, config)
    }, [value])

    return (
        <CModal visible={visible} onClose={() => setVisible(false)}>
            <CModalHeader>
                <CModalTitle>Create a new user</CModalTitle>
            </CModalHeader>
            <CForm onSubmit={(e) => handleSubmit(e)}>
                <CModalBody>
                    <CCol xs="12">
                        <CFormLabel> Enter user info.. </CFormLabel>
                    </CCol>

                    <CCol className={'mb-4'} xs="12">
                        <CFormInput onChange={(e) => setUserName(e.target.value)} type="text" placeholder="Enter Username" />
                    </CCol>
                    <CCol className={'mb-4'} xs="12">
                        <CFormInput onChange={(e) => setPassWord(e.target.value)} type="text" placeholder="Enter Password" />
                    </CCol>
                    <CCol className={'mb-4'} xs="12">
                        <CFormInput onChange={(e) => setFullName(e.target.value)} type="text" placeholder="Enter Full-Name" />
                    </CCol>
                    <CCol className={'mb-4'} xs="12">
                        <CFormInput onChange={(e) => setRole(e.target.value)} type="text" placeholder="Enter User-Role" />
                    </CCol>

                </CModalBody>
                <CModalFooter>
                    <CButton style={{ color: 'white' }} color="info" type="submit" >Create</CButton>
                </CModalFooter>
            </CForm>
        </CModal>
    )
}

export default AddUserModal