import React from 'react'
import {
  CContainer,
  CNavItem,
  CNavbar,
  CNavbarBrand,
} from '@coreui/react'

import { AppHeaderDropdown } from './header/index'


const AppHeader = () => {
  return (
    <CNavbar expand="lg" className='mb-4' style={{ position: 'sticky', top: '0px', zIndex: '3', borderBottom: 'solid', backgroundColor: 'white', boxShadow: "5px 10px 10px #d6ebff" }} >
      <CContainer fluid>
        <CNavbarBrand > <h2>URL Shortener</h2></CNavbarBrand>

        <AppHeaderDropdown />

      </CContainer>
    </CNavbar>
  )
}

export default AppHeader
