import React, { Suspense } from 'react'
import RequireAuth from './components/RequireAuth'
import { Navigate, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import Layout from './components/Layout'

// const loading = (
//   <div className="pt-3 text-center">
//     <div className="sk-spinner sk-spinner-pulse"></div>
//   </div>
// )


// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))

export function getToken() {
  const tokenString = sessionStorage.getItem('token');
  const userToken = JSON.parse(tokenString);
  return userToken?.token
}
const App = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route path='' element={<Navigate to='dashboard' />} />
          <Route exact path="/login" element={<Login />} />
          <Route element={<RequireAuth />}>
            <Route exact path="/dashboard" name="Dashboard" element={<Dashboard />} />
          </Route>
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/*" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
        </Route>
      </Routes>
    </>

  )
}


export default App
