import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import AppHeader from '../components/AppHeader'
import AppFooter from '../components/AppFooter'
import { CContainer } from '@coreui/react'

import { getToken } from '../App'
const RequireAuth = () => {
    const location = useLocation()

    return (
        getToken()
            ? <><AppHeader/> <CContainer lg> <Outlet /> </CContainer>  <AppFooter /></>
            : <Navigate to='/login' state={{ from: location }} replace />
    )
}

export default RequireAuth