import React, { useState } from 'react'
import {
  CAvatar,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import { useNavigate } from 'react-router-dom'
import { cilUser, cilAccountLogout, cilPlus } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import AddUserModal from './AddUserModal'

const AppHeaderDropdown = () => {
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate();
  const handelLogOut = () => {
    sessionStorage.removeItem('token');
    navigate('/', { replace: true })
  }
  const getName = () => {
    const userName = sessionStorage.getItem('token');
    const name = JSON.parse(userName)
    return name?.user?.fullname
  }

  const getRole = () => {
    const userName = sessionStorage.getItem('token');
    const name = JSON.parse(userName)
    return name?.user?.role
  }

  return (
    <CDropdown style={{ listStyleType: 'none', border: '2px solid', borderRadius: '50%' }} variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar color="white" size="md">  <CIcon style={{ color: 'black' }} icon={cilUser} /></CAvatar>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">Info</CDropdownHeader>
        <CDropdownItem style={{ cursor: 'pointer' }} >
          <CIcon icon={cilUser} className="me-2" />
          {getName()}
        </CDropdownItem>
        {getRole() === 'super' ?
          <CDropdownItem style={{ cursor: 'pointer' }} onClick={() => { setVisible(true) }}>
            <AddUserModal visible={visible} setVisible={setVisible} />
            <CIcon icon={cilPlus} className="me-2" />
            Add new user
          </CDropdownItem>
          : null
        }
        <CDropdownItem style={{ cursor: 'pointer' }} onClick={() => { handelLogOut() }}>
          <CIcon icon={cilAccountLogout} className="me-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
